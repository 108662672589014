<template>
  <div class="PlanejamentoDpmProdutosComplementares">
    <div class="mt-2 mb-2 title-float accent--text">
      {{`${$tc('label.produtos_tradicionais', 2)}`}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels v-model="painel">
            <v-expansion-panel>
              <v-expansion-panel-header class="pb-0 pt-0">
                <v-row align="start" justify="space-between" class="pr-8">
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${$t('label.volume_realizado')}: `}}</b>{{getNumber(sumVolumeRealizado)}}
                  </span>
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${$t('label.volume_meta')}: `}}</b>{{getNumber(sumVolumeMeta)}}
                  </span>
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${$t('label.pontuacao_realizada')}: `}}</b>{{getNumber(sumPontuacaoRealizada)}}
                  </span>
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${$t('label.pontuacao_meta')}: `}}</b>{{getNumber(sumPontuacaoMeta)}}
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                     >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small @mouseover="abrirMetaMinimaProdutos"  v-bind="attrs"  v-on="on" >info</v-icon>
                    </template>
                      <v-card>
                        <v-card-text>
                          <v-data-table
                          :headers="cabecalhoMetaProduto"
                          :items="metaProdutos"
                          dense
                          disable-filtering
                          disable-pagination
                          disable-sort
                          hide-default-footer>
                            <template v-slot:item.pontuacaoMeta="{ item }">
                            {{getNumber(item.pontuacaoMeta)}}
                            </template>
                        </v-data-table>
                        </v-card-text>
                      </v-card>

                    </v-menu>
                  </span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="cabecalho"
                  :items="produtosServicos"
                  dense
                  disable-filtering
                  disable-pagination
                  disable-sort
                  fixed-header
                  :height="400"
                  hide-default-footer>
                  <template v-slot:item.pontoUnitario="{ item }">
                    {{getNumber(item.pontoUnitario)}}
                  </template>
                  <template v-slot:item.volumeRealizado="{ item }">
                    {{getNumber(item.volumeRealizado)}}
                  </template>
                  <template v-slot:item.volumeMeta="{ item }">
                    <input-decimal
                      :disabled="somenteLeitura"
                      v-model="item.volumeMeta"
                      single-line
                      hide-details
                      dense
                      align-right
                      :rules="[rules.maximoPadrao]"
                      @input="volumeMetaAlterado"
                      class="py-0 mt-0 mb-1"
                      style="text-align:right"
                      />
                  </template>
                  <template v-slot:item.pontuacaoRealizada="{ item }">
                    {{getNumber(item.pontuacaoRealizada)}}
                  </template>
                  <template v-slot:item.pontuacaoMeta="{ item }">
                    {{getNumber(item.pontuacaoMeta)}}
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <td class="text-left" colspan="4" style="width:156px;font-weight:bold">{{ $t('label.total') }}:</td>
                      <td style="font-weight:bold;text-align:right">
                        {{getNumber(sumVolumeRealizado)}}
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getNumber(sumVolumeMeta)}}
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getNumber(sumPontuacaoRealizada)}}
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getNumber(sumPontuacaoMeta)}}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getNumber } from '../../../../produto/common/functions/helpers';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  props: {
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    produtosServicos: Array,
    anoAtualPeriodo: Number,
    anoAnteriorPeriodo: Number,
    segmentosHabilitados: Array,
  },
  components: {
    InputDecimal,
  },
  computed: {
    sumVolumeRealizado() {
      if (!this.produtosServicos || this.produtosServicos.length === 0) {
        return 0;
      }
      return this.produtosServicos.map((i) => i.volumeRealizado)
        .reduce((p, a) => p + a, 0);
    },
    sumVolumeMeta() {
      if (!this.produtosServicos || this.produtosServicos.length === 0) {
        return 0;
      }
      return this.produtosServicos.map((i) => i.volumeMeta)
        .reduce((p, a) => p + a, 0);
    },
    sumPontuacaoRealizada() {
      if (!this.produtosServicos || this.produtosServicos.length === 0) {
        return 0;
      }
      return this.produtosServicos.map((i) => i.pontuacaoRealizada)
        .reduce((p, a) => p + a, 0);
    },
    sumPontuacaoMeta() {
      if (!this.produtosServicos || this.produtosServicos.length === 0) {
        return 0;
      }
      return this.produtosServicos.map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
      painel: -1,
      cabecalho: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmentos',
          align: 'start',
          width: '156',
        },
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
          width: '76',
        },
        {
          text: this.$t('label.marca'),
          value: 'marca',
          align: 'start',
          width: '143',
        },
        {
          text: this.$t('label.ponto_unitario'),
          value: 'pontoUnitario',
          align: 'end',
          width: '164',
        },
        {
          text: `${this.$t('label.volume_realizado')} ${this.anoAnteriorPeriodo}`,
          value: 'volumeRealizado',
          align: 'end',
        },
        {
          text: this.$t('label.volume_meta'),
          value: 'volumeMeta',
          align: 'end',
          width: '200',
        },
        {
          text: `${this.$t('label.pontuacao_realizada')} ${this.anoAnteriorPeriodo}`,
          value: 'pontuacaoRealizada',
          align: 'end',
        },
        {
          text: this.$t('label.pontuacao_meta'),
          value: 'pontuacaoMeta',
          align: 'end',
        },
      ],
      cabecalhoMetaProduto: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmento',
          align: 'start',
        },
        {
          text: this.$tc('label.pontuacao_meta', 1),
          value: 'pontuacaoMeta',
          align: 'end',
        },
      ],
      metaMinimaProdutosDialog: false,
      metaProdutos: [],
    };
  },
  methods: {
    getNumber,
    volumeMetaAlterado() {
      setTimeout(() => this.recalculaVariaveis());
    },
    recalculaVariaveis() {
      this.produtosServicos.forEach((item) => {
        const { volumeMeta, pontoUnitario } = item;
        item.pontuacaoMeta = volumeMeta * pontoUnitario;
      });

      setTimeout(() => this.$forceUpdate());
      this.$emit('RECALCULO_VARIAVEIS');
    },
    abrirMetaMinimaProdutos() {
      this.calcularMetaProdutos();
      this.metaMinimaProdutosDialog = true;
    },
    calcularMetaProdutos() {
      this.metaProdutos = [];
      this.produtosServicos.forEach((marca) => {
        if (this.segmentosHabilitados.includes(marca.segmentos ? marca.segmentos.toLowerCase() : '')) {
          const metas = this.metaProdutos.filter((m) => m.segmento === marca.segmentos);
          if (metas.length > 0) {
            const meta = metas[0];
            meta.pontuacaoMeta += marca.pontuacaoMeta;
          } else {
            this.metaProdutos.push({
              segmento: marca.segmentos,
              pontuacaoMeta: marca.pontuacaoMeta,
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.preview {
  font-size:.8rem;
}
</style>
<style>
.PlanejamentoDpmProdutosComplementares .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
