<template>
  <div style="width: 100%;">
    <div class="mt-2 mb-2  title-float accent--text">
      {{$tc('label.classificacao', 1)}}
    </div>
    <div class="px-0 justify-space-between">
      <div class="d-flex justify-space-between">
        <div class="flex-lg-fill align-stretch order-1 m-1 indic p-0">
          <v-card style="margin-bottom: 10px; padding: 0; min-height: 175px !important;">
            <v-list subheader density="compact" style="max-height: 175px !important; padding: 0">
              <v-subheader style="max-height: 40px !important;font-weight: bold;">
                <span>{{$tc('label.indicador', 2)}}</span>
              </v-subheader>
              <v-list-item style="max-height: 30px !important;" density="compact"
                v-for="(indicador,index) in indicadores"
                :key="index">
                <v-list-item-content dense style="max-height: 30px !important;">
                  <v-row class="px-4" style="max-height: 30px !important;">
                    <span>{{$t(indicador.nome)}}</span>
                    <v-spacer />
                    <span>{{getNumber(indicador.valor)}}</span>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-list-item density="compact" dense style="max-height: 30px !important;">
                <v-list-item-content dense density="compact" style="max-height: 30px !important;">
                  <v-row class="px-4" style="max-height: 30px !important;">
                    <b>{{ $t('label.total') }}:</b>
                    <v-spacer />
                    <b>{{getNumber(sumIndicadores)}}</b>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card>
            <v-data-table
              :headers="novoCabecalhoMix"
              :items="novoLinhaMix"
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer
              dense>
            </v-data-table>
          </v-card>
        </div>
        <div class="flex-sm-fill align-stretch order-0 d-flex flex-column classific justify-space-between m-2" style="margin-right: 20px;">
          <v-text-field
            filled
            hide-details
            readonly
            :label="$t('label.crescimento_produtos_estrategicos')"
            :value="getPercent(classificacao.crescimentoProdutosEstrategicos)"
            class="py-2 align-stretch"
            />
          <v-text-field
            filled
            hide-details
            readonly
            :label="$t('label.crescimento_total')"
            :value="getPercent(classificacao.crescimentoTotal)"
            class="py-2 align-stretch"
            />
          <v-text-field
            filled
            hide-details
            readonly
            :label="$t('label.classificacao')"
            :value="(classificacao.nome === 'nao_aderiu' ? $t('label.classificacao_nao_aderiu') : classificacao.nome)"
            class="py-2 align-stretch"
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPercent, getNumber } from '../../../../produto/common/functions/helpers';

export default {
  props: {
    objetoPassoGeral: {
      type: Object,
      required: true,
      gruposCultura: Array,
    },
    gruposCultura: Array,
    indicadores: Array,
    classificacao: Object,
    produtosServicos: Array,
    anoAtualPeriodo: {
      type: Number,
      required: true,
    },
    anoAnteriorPeriodo: {
      type: Number,
      required: true,
    },
    segmentosHabilitados: Array,
    realizadoMarcas: Object,
  },
  data() {
    return {
      metaTotal: null,
      cabecalhoMix: [
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
        },
        {
          text: this.anoAnteriorPeriodo.toString(),
          value: 'anoAnterior',
          align: 'end',
        },
        {
          text: this.anoAtualPeriodo.toString(),
          value: 'anoAtual',
          align: 'end',
        },
      ],
      novoCabecalhoMix: [
        {
          text: this.$t('label.ano_mix'),
          value: 'anoMix',
          align: 'start',
        },
      ],
      novoLinhaMix: [{ anoMix: this.anoAnteriorPeriodo }, { anoMix: this.anoAtualPeriodo }],
      linhasGrupo: [],
      linhasMix: [],
      mixBuffer: [],
      mapaMarcasMix: [],
      gruposCulturaPrincipal: [],
    };
  },
  computed: {
    listaMarcas() {
      return this.gruposCultura
        .map((grupo) => grupo.marcas)
        .flat()
        .concat(this.produtosServicos);
    },
    sumIndicadores() {
      if (!this.indicadores || this.indicadores.length === 0) {
        return 0;
      }
      return this.indicadores.map((i) => i.valor)
        .reduce((p, a) => p + a, 0);
    },
    totalRealizado() {
      return Object.values(this.realizadoMarcas)
        .map((item) => (item.volume * (item.pontuacaoUnitaria === undefined ? 0 : item.pontuacaoUnitaria)))
        .reduce((p, a) => p + a, 0);
    },
    sumAnoAtual() {
      if (!this.linhasGrupo || this.linhasGrupo.length === 0) {
        return 0;
      }
      return this.linhasGrupo.map((i) => i.anoAtual)
        .reduce((p, a) => p + a, 0)
        + this.sumAnoAtualProdutosServicos;
    },
    sumCrescimento() {
      if (this.totalRealizado === 0) {
        return 0;
      }
      return ((this.sumAnoAtual - this.totalRealizado) / this.totalRealizado) * 100;
    },
    sumAnoAtualProdutosServicos() {
      if (!this.produtosServicos || this.produtosServicos.length === 0) {
        return 0;
      }
      return this.produtosServicos.map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);
    },
  },
  methods: {
    getPercent,
    getNumber,
    atualiza(calculos) {
      this.classificacao.crescimentoGrupo = calculos.grupoCulturaPrincipal.crescimento;
      this.classificacao.crescimentoTotal = calculos.crescimentoTotal;
      this.metaTotal = calculos.metaTotal;
    },
    calculaValores() {
      this.calculaValoresGrupos();
      this.calculaValoresMix();
      this.calculaValoresGrupoCulturaPricipais();
      this.calculaCrescimentoProdutosEstrategicos();
    },
    calculaCrescimentoProdutosEstrategicos() {
      let anoAnterior = 0;
      let anoAtual = 0;
      this.gruposCultura.forEach((grupo) => {
        anoAnterior += grupo.anoAnterior;
        anoAtual += grupo.anoAtual;
      });
      if (anoAnterior === 0 && anoAtual > 0) {
        this.classificacao.crescimentoProdutosEstrategicos = 100;
      }
      if (anoAnterior === 0 && anoAtual === 0) {
        this.classificacao.crescimentoProdutosEstrategicos = 0;
      }
      if (anoAnterior > 0) {
        this.classificacao.crescimentoProdutosEstrategicos = (((anoAtual - anoAnterior) / anoAnterior) * 100).toFixed(2);
      }
    },
    calculaValoresGrupos() {
      this.linhasGrupo = this.gruposCultura.map((grupo) => {
        const {
          grupoCultura,
          metaDefinida,
          crescimento,
          marcas,
          idGrupoCultura,
        } = grupo;
        const anoAnterior = marcas.map((m) => m.pontuacaoRealizada)
          .reduce((p, a) => p + a, 0);

        grupo.anoAnterior = anoAnterior;
        grupo.anoAtual = metaDefinida;

        return {
          grupoCultura,
          anoAnterior,
          anoAtual: metaDefinida,
          crescimento,
          idGrupoCultura,
        };
      });
    },
    calculaValoresGrupoCulturaPricipais() {
      const gruposOrdenados = [...this.linhasGrupo];
      gruposOrdenados.sort((a, b) => {
        if (a.anoAtual < b.anoAtual) {
          return 1;
        } if (a.anoAtual > b.anoAtual) {
          return -1;
        }
        return 0;
      });
      const culturaPrincipal = gruposOrdenados[0];
      this.gruposCulturaPrincipal = [];
      const marcas = [];
      this.gruposCultura.forEach((grupo) => {
        if (culturaPrincipal.idGrupoCultura === grupo.idGrupoCultura) {
          grupo.marcas.forEach((marca) => {
            marcas.push(marca);
          });
        }
      });
      marcas.forEach((marca) => {
        if (this.segmentosHabilitados.includes(marca.segmentos ? marca.segmentos.toLowerCase() : '')) {
          const gruposCulturaPrincipal = this.gruposCulturaPrincipal.filter((m) => m.segmento === marca.segmentos);
          if (gruposCulturaPrincipal.length > 0) {
            const grupoCulturaPrincipal = gruposCulturaPrincipal[0];
            grupoCulturaPrincipal.anoAnterior += marca.pontuacaoRealizada;
            grupoCulturaPrincipal.anoAtual += marca.pontuacaoMeta;
          } else {
            this.gruposCulturaPrincipal.push({
              segmento: marca.segmentos,
              anoAnterior: marca.pontuacaoRealizada,
              anoAtual: marca.pontuacaoMeta,
            });
          }
        }
      });
      this.gruposCulturaPrincipal.forEach((grupoCulturaPrincipal) => {
        if (grupoCulturaPrincipal.anoAnterior === 0 && grupoCulturaPrincipal.anoAtual > 0) {
          grupoCulturaPrincipal.crescimento = 100;
        }
        if (grupoCulturaPrincipal.anoAnterior === 0 && grupoCulturaPrincipal.anoAtual === 0) {
          grupoCulturaPrincipal.crescimento = 0;
        }
        if (grupoCulturaPrincipal.anoAnterior > 0) {
          grupoCulturaPrincipal.crescimento = (((grupoCulturaPrincipal.anoAtual - grupoCulturaPrincipal.anoAnterior)
          / grupoCulturaPrincipal.anoAnterior) * 100).toFixed(2);
        }
      });
      this.crescimentoSegmentos = this.gruposCulturaPrincipal;
      this.$emit('crescimentoSegmentos', this.crescimentoSegmentos);
    },
    getPontuacaoRealizadaMarca(idMarca) {
      const marca = this.realizadoMarcas[idMarca];
      if (!marca) {
        return 0;
      }
      const { volume, pontuacaoUnitaria } = marca;
      return volume * pontuacaoUnitaria;
    },
    marcasPorMix(mix) {
      if (!this.mapaMarcasMix[mix]) {
        this.mapaMarcasMix[mix] = this.listaMarcas
          .filter((m) => m.mix === mix)
          .map((m) => m.idMarca)
          .reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);
      }
      return this.mapaMarcasMix[mix];
    },
    calculaValoresMix() {
      const sumPontuacaoMeta = this.listaMarcas
        .map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);

      const divisorAnterior = this.totalRealizado === (0 || NaN) ? 1 : this.totalRealizado;
      const divisorAtual = sumPontuacaoMeta === (0 || NaN) ? 1 : sumPontuacaoMeta;
      this.listaMarcas.forEach((value) => {
        if (this.mixBuffer.includes(value.mix)) {
          return;
        }
        this.mixBuffer.push(value.mix);
        this.novoCabecalhoMix.push({
          text: value.mix,
          value: value.mix,
          align: 'center',
        });
      });
      this.novoCabecalhoMix.sort((a, b) => {
        if (a.value === 'anoMix' || b.value === 'anoMix') {
          return 0;
        }
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      });
      this.linhasMix = this.listaMarcas
        .map((i) => i.mix)
        .reduce((x, y) => (x.includes(y) ? x : [...x, y]), [])
        .map((mix) => {
          let pontuacaoRealizadaMix = 0,
            pontuacaoMetaMix = 0;

          this.marcasPorMix(mix).forEach((idMarca) => {
            const pontuacaoRealizada = this.getPontuacaoRealizadaMarca(idMarca);
            pontuacaoRealizadaMix += pontuacaoRealizada;
          });

          this.listaMarcas
            .filter((m) => m.mix === mix)
            .map((m) => m.pontuacaoMeta)
            .forEach((pontuacaoMeta) => {
              pontuacaoMetaMix += pontuacaoMeta;
            });
          return {
            mix,
            anoAnterior: (pontuacaoRealizadaMix / divisorAnterior) * 100,
            anoAtual: (pontuacaoMetaMix / divisorAtual) * 100,
          };
        });
      this.linhasMix.forEach((value) => {
        this.novoLinhaMix[0][value.mix] = getPercent(Number.isNaN(value.anoAnterior) ? 0 : value.anoAnterior.toFixed(2));
        this.novoLinhaMix[1][value.mix] = getPercent(Number.isNaN(value.anoAtual) ? 0 : value.anoAtual.toFixed(2));
      });
      const novoLinhaMixBuffer = [];
      this.novoLinhaMix.forEach((value) => {
        const obj = {};
        Object.keys(value)
          .sort().forEach((v) => {
            obj[v] = value[v];
          });
        novoLinhaMixBuffer.push(obj);
      });
      this.novoLinhaMix = novoLinhaMixBuffer;
    },
  },
};
</script>
<style>
.classific input {
  text-align: center !important;
  min-height: 58px !important;
  font-size: 20px;
}
.classific label{
  text-align: center !important;
  right: -40% !important;
  color: rgb(43, 43, 43) !important;
  font-weight: bold;
  font-size: 25px;
}

.indic {
  width: 40%;
  max-height: 50px !important;
}
table span {
  color: rgb(43, 43, 43) !important;
  font-size: 15px;
}
.v-subheader {
  color: rgb(43, 43, 43) !important;
}
</style>
