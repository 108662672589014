<template>
  <div>
    <upload-file-form
      v-if="podeAnexar"
      ref="uploadArquivo"
      :title="$t('label.upload_anexo')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
    </upload-file-form>

    <div class="painel-anexo">
      <v-row>
        <div v-if="podeAnexar" class="botao-anexar">
          <v-btn icon
            @click="() => $refs.uploadArquivo.open()">
            <v-icon>
              attach_file
            </v-icon>
          </v-btn>
        </div>
        <list-files :habilitar-exclusao="podeAnexar" ref="listFiles" :uri="uri"></list-files>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import basePath from '../../../../common/functions/api-resource';
import ListFiles from '../../../../shared-components/files/ListFiles';
import UploadFileForm from '../../../../shared-components/upload/UploadFileForm';

export default {
  name: 'PlanejamentoContratoFormAnexoArquivo',
  components: {
    UploadFileForm,
    ListFiles,
  },
  props: {
    idContrato: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      contrato: {
        idcontrato: 1,
      },
      descricaoArquivo: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/contrato');
      return `${urlUpload}/${this.idContrato}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/contrato');
      return `${urlUpload}/${this.idContrato}/upload`;
    },
    podeAnexar() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_CRUD' || el === 'CONT_UPLOAD_ARQ').length;
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
      // this.$emit('ApuracaoAcaoArquivos_uploadSucesso');
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
  padding: 12px 0 0 12px;
  font-size: 16px !important;
  color: red;
}
.painel-anexo .container.mx-0 {
  max-width: 97% !important;
}
</style>
