var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PlanejamentoDpmProdutosComplementares"},[_c('div',{staticClass:"mt-2 mb-2 title-float accent--text"},[_vm._v(" "+_vm._s(("" + (_vm.$tc('label.produtos_tradicionais', 2))))+" ")]),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-expansion-panels',{model:{value:(_vm.painel),callback:function ($$v) {_vm.painel=$$v},expression:"painel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pb-0 pt-0"},[_c('v-row',{staticClass:"pr-8",attrs:{"align":"start","justify":"space-between"}},[_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((_vm.$t('label.volume_realizado')) + ": ")))]),_vm._v(_vm._s(_vm.getNumber(_vm.sumVolumeRealizado))+" ")]),_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((_vm.$t('label.volume_meta')) + ": ")))]),_vm._v(_vm._s(_vm.getNumber(_vm.sumVolumeMeta))+" ")]),_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((_vm.$t('label.pontuacao_realizada')) + ": ")))]),_vm._v(_vm._s(_vm.getNumber(_vm.sumPontuacaoRealizada))+" ")]),_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((_vm.$t('label.pontuacao_meta')) + ": ")))]),_vm._v(_vm._s(_vm.getNumber(_vm.sumPontuacaoMeta))+" "),_c('v-menu',{attrs:{"open-on-hover":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"mouseover":_vm.abrirMetaMinimaProdutos}},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.cabecalhoMetaProduto,"items":_vm.metaProdutos,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pontuacaoMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontuacaoMeta))+" ")]}}])})],1)],1)],1)],1)])],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.produtosServicos,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","fixed-header":"","height":400,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pontoUnitario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontoUnitario))+" ")]}},{key:"item.volumeRealizado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeRealizado))+" ")]}},{key:"item.volumeMeta",fn:function(ref){
var item = ref.item;
return [_c('input-decimal',{staticClass:"py-0 mt-0 mb-1",staticStyle:{"text-align":"right"},attrs:{"disabled":_vm.somenteLeitura,"single-line":"","hide-details":"","dense":"","align-right":"","rules":[_vm.rules.maximoPadrao]},on:{"input":_vm.volumeMetaAlterado},model:{value:(item.volumeMeta),callback:function ($$v) {_vm.$set(item, "volumeMeta", $$v)},expression:"item.volumeMeta"}})]}},{key:"item.pontuacaoRealizada",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontuacaoRealizada))+" ")]}},{key:"item.pontuacaoMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontuacaoMeta))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-left",staticStyle:{"width":"156px","font-weight":"bold"},attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.$t('label.total'))+":")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.sumVolumeRealizado))+" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.sumVolumeMeta))+" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.sumPontuacaoRealizada))+" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.sumPontuacaoMeta))+" ")])])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }